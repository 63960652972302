import { Component } from 'react'
import {
  useState,
  useEffect,
} from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Link from 'next/link'
import Router, {
  useRouter,
} from 'next/router'
import {
  createUseStyles,
  withStyles,
} from 'react-jss'
import {
  connect,
} from 'react-redux'
import classNames from 'classnames'
import merge from 'deepmerge'
import { Fade } from 'react-slideshow-image'
import Slider from 'react-slick'
import {
  Image,
  BackgroundImage,
} from 'react-image-and-background-image-fade'
import Collapse from '@material-ui/core/Collapse'
import MuiButton from '@material-ui/core/Button'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  getShops,
} from '../redux/api/actions'
import Channel from '../utils/Channel'
import getViewportForCoordinates from '../utils/getViewportForCoordinates'
import setCookieAndLoadProfile from '../utils/setCookieAndLoadProfile'
import redirectIfLoggedIn from '../utils/redirectIfLoggedIn'
import Colors from '../utils/Colors'
import {
  BREAKPOINTS,
} from '../utils/theme'
import {
  DEFAULT_SHOP_IMAGE,
} from '../utils/Constants'
import getUrlForPhoto from '../utils/getUrlForPhoto'
import PublicHeader from '../components/PublicHeader'
import Logo from '../components/Logo'
import Button from '../components/Button'
import Ribbon from '../components/Ribbon'
import Footer from '../components/Footer'
import TestimonialWomanShortHair from '../components/home/TestimonialWomanShortHair'
import TestimonialManShortHair from '../components/home/TestimonialManShortHair'
import TestimonialWomanLongHair from '../components/home/TestimonialWomanLongHair'
import TestimonialManGlasses from '../components/home/TestimonialManGlasses'
import LocationPinIcon from '../components/icons/LocationPinIcon'
import DownArrowIcon from '../components/icons/DownArrowIcon'
import NavMenuIcon from '../components/icons/NavMenuIcon'

const ExploreMap = dynamic(() => import('../components/home/ExploreMap'))

import 'react-slideshow-image/dist/styles.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const HEADER_TRANSITION_HEIGHT = 100
const HERO_HEIGHT_LARGE = 800
const HERO_HEIGHT_MEDIUM = 458
const HERO_IMAGE_DURATION = 4000
const HERO_IMAGE_TRANSITION_DURATION = 800

const ITEM_IMAGE_HEIGHT = 260

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray
const mapEasing = t => 1 - (1 - t) * (1 - t)

const HERO_IMAGES = [
  'https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/eating-background-small.webp',
  'https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/pickup-background-small.webp',
  'https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/curry-background-small.webp',
]

const shibuya = {
    hero: {
      title: '300店舗から選び放題\n1食ワンコイン価格',
      text: `サブスク×事前予約の新しい食体験サービス`,
      appDownload: false,
    },
    features: {
      text1: 'そのままでもリーズナブル。サブスクプランに加入すると、さらにお得に楽しめます。',
      text2: '事前予約をすると飲食店ですぐに受け取りが可能\nです。ランチタイムの混雑や忙しくて夕食が作る時\n間がないという悩みから開放されます。\n現在渋谷駅恵比寿駅周辺にて\nデリバリーも実施中！',
    },
    recommend: {
      subtitle: `話題のお店の料理をお得な料金で楽しめます！`,
    },
    plans: [{
      titleTop: false,
      title: '3食トライアル',
      price: 290,
      priceWithTax: 313,
      strikethroughPrice: '¥590/1食',
      ribbon: '1週間お試し',
      perType: 'ticket',
      description: 'トライアル終了後\n12食プランに自動更新',
    }, {
      titleTop: '\\1番人気/',
      title: '12食プラン',
      price: 590,
      priceWithTax: 637,
      perType: 'ticket',
      description: '30日間で最大12食ご利用可能\n月々¥7,080（税込¥7,646）',
    }, {
      titleTop: '\\お得に活用/',
      title: '平日毎日1食プラン',
      price: 490,
      priceWithTax: 529,
      perType: 'day',
      description: '土日を除く昼or夜1回ご利用可能\n月々¥9,800（税込¥10,584）',
    }],
    bottomButton: true,
  }

const DYNAMIC_COPY = {
  default: {
    hero: {
      title: `手づくり料理を、\n手軽に受け取り。`,
      text: `定額制テイクアウトアプリ\nPOTLUCK（ポットラック）`,
      appDownload: true,
    },
    appInfo: {
      title: `お金も時間も、我慢しなくていい。\nPOTLUCKなら、毎日の食事が思いのまま`,
      text1: `毎日ごはんをつくるのは大変。\nかといって外食ばかりだとお金がかかるし、\nついつい食べすぎてしまうことも。\n`,
      text2: `コンビニも便利で良いけど、\n手づくりのあたたかい料理を食べたい。\n`,
      text3: `POTLUCK（ポットラック）は、そんなあなたの味方です。\n忙しいランチタイムも、一日頑張った日の夜ごはんも。\nもっと自由で、あたたかい食生活を提案します。`,
    },
    features: {
      text1: `定額制で、1日2回までのご注文が可能です。1週\n間3食トライアル、12食プラン、毎日プランの3つ\nから選択できます。`,
      text2: `事前予約をすると飲食店ですぐに受け取りが可能\nです。ランチタイムの混雑や忙しくて夕食が作る時\n間がないという悩みから開放されます。`,
      text3: `POTLUCK（ポットラック）は、様々な\nジャンルのメニューを取り揃えています。\n提供エリアは随時拡大中です。`,
    },
    recommend: {
      subtitle: `お楽しみいただけるメニュー事例`,
    },
    howToUse: {
      section1: {
        title: `1. メニューを選ぶ`,
        text: `様々なジャンルのメニューを\n取り揃えています。あなたが今日\n食べたいメニューを選びましょう。`,
      },
      section2: {
        title: `2. 受け取り時間を指定して注文`,
        text: `受取時間を指定して注文！\nランチ予約は前日17時半〜当日朝10時半、\nディナーは当日10時半〜17時半までです。`,
      },
      section3: {
        title: `3. 店舗で受け取り`,
        text: `注文時に指定した時間になったら、\n店舗でメニューを受け取りましょう！\nさっそく素敵なランチタイムを始めよう！`,
      },
    },
    plans: [{
      titleTop: false,
      title: '3食トライアル',
      price: 290,
      priceWithTax: 313,
      strikethroughPrice: '¥590/1食',
      ribbon: '1週間お試し',
      perType: 'ticket',
      description: 'トライアル終了後\n12食プランに自動更新',
    }, {
      titleTop: false,
      title: '12食プラン',
      price: 590,
      priceWithTax: 637,
      perType: 'ticket',
      description: '30日間で最大12食ご利用可能\n月々¥7,080（税込¥7,646）',
    }, {
      titleTop: false,
      title: '平日毎日1食プラン',
      price: 490,
      priceWithTax: 529,
      perType: 'day',
      description: '土日を除く昼or夜1回ご利用可能\n月々¥9,800（税込¥10,584）',
    }],
  },
  facebookshibuya: shibuya,
  instagram: shibuya,
}

const renderDynamicCopyString = dynamicCopyString => dynamicCopyString.split('\n').map((line, index) => (
  <div key={index}>
    {line}
  </div>
))

const MEALS = [{
  id: 1,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/carousel-images/1.webp',
  name: 'バーテンダーの深夜カレー',
  shop: 'BAR BUTTER',
  neighborhood: '渋谷',
}, {
  id: 2,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/carousel-images/2.webp',
  name: '当店自慢！極太生パスタを使ったトマトソースパスタ　魚介と水菜、アンチョビ風味',
  shop: 'OSTERIA CASA 吉田PASTABAR',
  neighborhood: '渋谷',
}, {
  id: 3,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/carousel-images/3.webp',
  name: 'ホワイトオムライス　濃厚デミグラスソース',
  shop: 'ウサギ',
  neighborhood: '渋谷',
}, {
  id: 4,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/carousel-images/4.webp',
  name: 'プルドポークオーバーライス',
  shop: 'ミクロコスモス',
  neighborhood: '渋谷',
}, {
  id: 5,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/carousel-images/5.webp',
  name: 'ジャークチキン丼（POTLUCK限定おためしver.）',
  shop: 'Good Wood Terrace(グッドウッドテラス)',
  neighborhood: '渋谷',
}, {
  id: 6,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/carousel-images/6.webp',
  name: '【限定5食】国産牛のローストビーフ丼',
  shop: 'Ma Maison Passagegarden',
  neighborhood: '渋谷',
}, {
  id: 7,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/carousel-images/7.webp',
  name: 'のり巻（サケ_焼き肉）アボカドサラダ付き',
  shop: 'Bookcafe days',
  neighborhood: '渋谷',
}, {
  id: 8,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/carousel-images/8.webp',
  name: '漬けまぐろねぎとろ丼（鶏唐揚げ1個付き）',
  shop: '柿家鮨',
  neighborhood: '渋谷・西麻布',
}, {
  id: 9,
  image: 'https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/carousel-images/9.webp',
  name: '豚しゃぶサラダ',
  shop: 'サンクスネイチャー',
  neighborhood: '恵比寿',
}]

const MAP_LOCATIONS = [{
  label: '渋谷',
  value: 'tokyo',
  latitude:  35.6580340,
  longitude: 139.7016360,
  zoom: 13,
}, {
  label: '恵比寿',
  value: 'ebisu',
  latitude: 35.646903,
  longitude: 139.709411,
  zoom: 13,
}, {
  label: '新宿',
  value: 'shinjuku',
  latitude: 35.690014,
  longitude: 139.701785,
  zoom: 13,
}]

const BUNDLES = [{
  title: '3食買い切りプラン',
  price: 650,
  priceWithTax: 702,
  perType: 'ticket',
  description: '無期限で\n3食ご利用可能',
}]

const TESTIMONIALS = [{
  image: <TestimonialWomanShortHair />,
  primaryText: '27歳・女性・総務',
  secondaryText: '「おいしくて健康的なお店の料理が、気軽に食べられる！」',
  caption: '今までコンビニでお弁当を買って食べることが多かったのですが、添加物が健康面で少し気になっていました。POTLUCKには、野菜たっぷりのヘルシーなメニューも多いので、身体が喜んでいる感じがして満足度が高いです！',
}, {
  image: <TestimonialManShortHair />,
  primaryText: '25歳・男性・営業',
  secondaryText: '「待ち時間や、食費のストレスから解放されました」',
  caption: '事前予約で受け取れるので、コンビニで並んだりお店で待つ時間が無くなりました。毎日プランを利用しているため、たくさん使えばワンコイン以内になるので、食費の節約にもなり助かっています！',
}, {
  image: <TestimonialWomanLongHair />,
  primaryText: '38歳・女性・企画',
  secondaryText: '「マンネリ化していたランチが楽しみに！」',
  caption: 'いつも同じようなランチを食べていて「ランチを済ます」感覚だったのですが、POTLUCKを始めてから「ランチを楽しむ」感覚に変わりました！新しいお店も増えていくので、毎日どのランチを食べるか選ぶのが楽しいです！',
}, {
  image: <TestimonialManGlasses />,
  primaryText: '34歳・男性・エンジニア',
  secondaryText: '「同僚と飲みに行くお店選びに困らなくなりました！」',
  caption: 'テイクアウトで料理の味がわかる事はもちろん、受け取りの際に店内の雰囲気を知ることができるのが魅力的です。自分では見つけられないような隠れ家的なお店も多く発見できて、仕事後に同僚と行きたいお店が増えました！',
}]

const MEDIA_LOGOS = [{
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/mbs.jpg',
  height: '59px',
  width: '113px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/fujitv.png',
  height: '84px',
  width: '91px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/tv-asahi.jpg',
  height: '78px',
  width: '78px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/prime-news-alpha.jpg',
  height: '63px',
  width: '112px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/abema-prime.png',
  height: '69px',
  width: '69px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/j-wave.png',
  height: '36px',
  width: '155px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/pococe.png',
  height: '39px',
  width: '93px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/nikkei.png',
  height: '19px',
  width: '165px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/vogue-japan.jpg',
  height: '52px',
  width: '117px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/smart.png',
  height: '52px',
  width: '117px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/karakoto.jpg',
  height: '34px',
  width: '116px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/techcrunch.jpg',
  height: '69px',
  width: '93px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/ami.png',
  height: '43px',
  width: '95px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/amp.png',
  height: '53px',
  width: '148px',
  link: '',
}, {
  image: 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/media-logos/career-hack.png',
  height: '39px',
  width: '163px',
  link: '',
}]

const FAQ_QUESTIONS = [{
  question: '1.予約時間は何時まで？',
  answer: 'POTLUCKは事前予約制を基本としています。ランチのご予約は前日17:30〜当日朝10:30まで、ディナーのご予約は当日10:30〜当日17:30までです。※一部店舗においては「POTLUCK NOW」という機能にて事前予約時間終了後もお受け取りが可能です。',
}, {
  question: '2.チケットの有効期限はいつまで？',
  answer: '定期購入プランのチケットは購入日から１ヶ月間有効となります。有効期限の無い12食買い切りプランもございます。',
}, {
  question: '3.出張や長期休暇でチケットを消化しきれない場合は？',
  answer: '「プランの一時停止」機能をご利用ください。一時停止をすると、チケットが使用できない代わりに最大2ヶ月間有効期限を延長することができます。\n※一時停止をご利用いただけるのは1サイクルに1回のみとなります。\n※2ヶ月後自動更新が再スタートします。',
}, {
  question: '4.お支払い方法は？',
  answer: '現在はVISA及びMasterCardのクレジットカードのみご利用いただけます。クレジットカードをお持ちでない場合はkyashもしくはバンドルカードなどのご利用をすることで、コンビニや銀行支払いなどが可能になります。（各サービスへのご質問はそれぞれの運営会社にお問い合わせください。）',
}, {
  question: '5.プランの解約方法は？',
  answer: 'POTLUCKの手続きはとっても簡単。面倒な契約や拘束は一切ありません。たった数回のクリックで、オンラインで簡単に休会（キャンセル）が可能です。キャンセル料金は一切なく、アカウントの開始やキャンセルはいつでも可能です。※アプリ上のマイページ＞アカウント＞プラン＞プランの解約',
}]

const useSectionStyles = createUseStyles(theme => ({
  container: {
    position: 'relative',
    backgroundColor: Colors.white,
    textAlign: 'center',
  },
  containerPink: {
    backgroundColor: Colors.primaryPink,

    '&:before': {
      zIndex: 2,
      position: 'absolute',
      top: -14,
      left: 0,
      right: 0,
      height: 14,
      background: 'linear-gradient(45deg,#ff6069 50%,transparent 52%),linear-gradient(315deg,#ff6069 50%,transparent 52%)',
      backgroundSize: '30px 30px',
      content: '""',
    },

    '&:after': {
      zIndex: 2,
      position: 'absolute',
      bottom: -14,
      left: 0,
      right: 0,
      height: 14,
      background: 'linear-gradient(45deg,#ff6069 50%,transparent 52%),linear-gradient(315deg,#ff6069 50%,transparent 52%)',
      backgroundSize: '30px 30px',
      content: '""',
      transform: 'rotate(180deg)',
    },
  },
  contentWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 1200,
    padding: theme.spacing.unit * 8,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing.unit * 4,
      paddingBottom: theme.spacing.unit * 4,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
  },
  contentWrapperNoHorizontalPadding: {
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
    maxWidth: 'none',
  },
  title: {
    fontFamily: 'Comfortaa',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '27px',
    textAlign: 'center',
    letterSpacing: '0.25em',
    color: Colors.primaryPink,
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '18px',
    },
  },
  titlePink: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  subtitle: {
    fontSize: 32,
    fontWeight: '500',
    lineHeight: '48px',
    letterSpacing: '0.1em',
    color: Colors.softText,
    marginTop: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '28px',
    },
  },
  subtitlePink: {
    color: Colors.white,
  },
  content: {
    paddingTop: theme.spacing.unit * 8,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing.unit * 2,
    },
  },
}))

const Section = ({ title, subtitle, color="white", children, noHorizontalPadding=false }) => {
  const classes = useSectionStyles()

  return (
    <div className={classNames(classes.container, {
      [classes.containerPink]: color === 'pink',
    })}>
      <div className={classNames(classes.contentWrapper, {
        [classes.contentWrapperNoHorizontalPadding]: noHorizontalPadding,
      })}>
        <div className={classNames(classes.title, {
          [classes.titlePink]: color === 'pink',
        })}>
          {title}
        </div>
        <div className={classNames(classes.subtitle, {
          [classes.subtitlePink]: color === 'pink',
        })}>
          {subtitle}
        </div>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </div>
  )
}

const SECTION_ITEM_SIZE = 316

const useSectionItemStyles = createUseStyles(theme => ({
  container: {
    width: SECTION_ITEM_SIZE,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  title: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '30px',
    textAlign: 'center',
    color: Colors.softText,
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '18px',
    }
  },
  text: {
    fontSize: 12,
    lineHeight: '24px',
    textAlign: 'center',
    color: Colors.primaryCharcoal,
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      lineHeight: '18px',
    }
  },
}))

const SectionItem = ({ image, title, text, className, imageClassName }) => {
  const classes = useSectionItemStyles()

  return (
    <div className={classNames(classes.container, className)}>
      <Image
        src={image}
        width={`${SECTION_ITEM_SIZE}px`}
        height={`${SECTION_ITEM_SIZE}px`}
        className={classNames(classes.item, imageClassName)}
        renderLoader={LazyLoader} />
      {title && (
        <div className={classes.title}>
          {title}
        </div>
      )}
      {text && (
        <div className={classes.text}>
          {text}
        </div>
      )}
    </div>
  )
}

const usePlanCardStyles = createUseStyles(theme => ({
  container: {
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    border: '3px solid #000000',
    borderRadius: 5,
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    }
  },
  containerFat: {
    width: 499,
    height: 121,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ribbon: {
    position: 'absolute',
    height: 137,
    width: 135,
    top: -37,
    left: -37,
  },
  content: {
    zIndex: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  contentFat: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '30px',
    textAlign: 'center',
    color: '#232323',
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '26px',
    },
  },
  titleFat: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  titleTop: {
    position: 'absolute',
    top: theme.spacing.unit,
    right: 0,
    left: 0,
    fontSize: 12,
    fontWeight: '600',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FF6069',
  },
  strikethroughPrice: {
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
    textDecoration: 'line-through',
    color: '#FF6069',
    marginBottom: -8,
  },
  price: {
    fontSize: 36,
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FF6069',
    marginTop: theme.spacing.unit * 2,
    marginLeft: 24,
  },
  priceMonth: {
    marginLeft: 0,
    marginRight: theme.spacing.unit * -1,
  },
  perType: {
    fontSize: 18,
  },
  tax: {
    fontSize: 10,
    lineHeight: '24px',
    textAlign: 'right',
    color: 'rgba(0, 0, 0, 0.3)',
  },
  description: {
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    color: '#666666',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  descriptionFat: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
    },
  },
  descriptionLine: {
    margin: 0,
  },
}))

const PlanCard = ({ plan, fat, className, ...rest }) => {
  const classes = usePlanCardStyles()

  let titleContents = [(
    <div key="title-top">
      {plan.titleTop &&
        <div className={classes.titleTop}>
          {plan.titleTop}
        </div>
      }
      <div
        key="title"
        className={classNames(classes.title, {
          [classes.titleFat]: fat,
        })}>
        {plan.title}
      </div>
    </div>
  )]

  if (plan.strikethroughPrice) {
    titleContents.push(
      <div
        key="strikethrough"
        className={classes.strikethroughPrice}>
        {plan.strikethroughPrice}
      </div>
    )
  }

  titleContents.push(
    <div
      key="price"
      className={classNames(classes.price, {
      [classes.priceMonth]: plan.perType,
    })}>
      ¥{plan.price}
      {plan.perType === 'ticket' && (
        <span className={classes.perType}>
          /1食
        </span>
      )}
      {plan.perType === 'day' && (
        <span className={classes.perType}>
          /1日
        </span>
      )}
      {plan.perType === 'month' && (
        <span className={classes.perType}>
          /1月
        </span>
      )}
      <span className={classes.tax}>
        （税込¥{plan.priceWithTax}）
      </span>
    </div>
  )

  if (fat) {
    titleContents = (
      <div>
        {titleContents}
      </div>
    )
  }

  return (
    <div
      {...rest}
      className={classNames([classes.container, className], {
        [classes.containerFat]: fat,
      })}>
      {plan.ribbon && (
        <Ribbon
          height={137}
          width={135}
          ribbon={plan.ribbon}
          className={classes.ribbon} />
      )}
      <div className={classNames(classes.content, {
        [classes.contentFat]: fat,
      })}>
        {titleContents}
        {plan.description && (
          <div className={classNames(classes.description, {
            [classes.descriptionFat]: fat,
          })}>
            {plan.description.split('\n').map((line, index) => (
              <p
                key={index}
                className={classes.descriptionLine}>
                {line}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const LazyLoader = () => (<div />)

const styles = theme => ({
  container: {
  },
  hero: {
    position: 'relative',
    clipPath: 'ellipse(92% 480px at 50% 40%)',
    [theme.breakpoints.down('sm')]: {
      clipPath: 'ellipse(100% 238px at 50% 45%)',
    }
  },
  heroImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    height: HERO_HEIGHT_LARGE,
    [theme.breakpoints.down('sm')]: {
      height: HERO_HEIGHT_MEDIUM,
    }
  },
  heroGradient: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'linear-gradient(90.98deg, rgba(0, 0, 0, 0.25) 14.17%, rgba(105, 105, 105, 0.15) 53.92%)',
    backgroundSize: 'cover',
    backgroundPosition: '80% 0%',
  },
  heroContentWrapper: {
    zIndex: 3,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heroContent: {
    width: '100%',
    maxWidth: 1200,
    paddingLeft: theme.spacing.unit * 6,
    paddingRight: theme.spacing.unit * 6,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
  },
  heroTitle: {
    color: Colors.white,
    fontSize: 56,
    marginTop: theme.spacing.unit * 20,
    fontWeight: 'bold',
    lineHeight: '72px',
    textShadow: '0px 0px 30px rgb(0 0 0 / 40%)',
    letterSpacing: '0.05em',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: 36,
      lineHeight: '48px',
      marginTop: theme.spacing.unit * 10,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      lineHeight: '32px',
      marginTop: theme.spacing.unit * 10,
    },
  },
  heroText: {
    color: Colors.white,
    fontSize: 24,
    lineHeight: '36px',
    textShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
    marginTop: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
      marginTop: theme.spacing.unit,
      marginLeft: 0,
    },
  },
  heroCtaAppLinks: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing.unit * 6,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: theme.spacing.unit * 4,
    },
  },
  ctaAppstore: {
    width: 189,
    height: 56,
    backgroundImage: 'url(https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/appstore.svg)',
  },
  ctaPlaystore: {
    width: 189,
    height: 56,
    backgroundImage: 'url(https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/landing-page/playstore.svg)',
    marginLeft: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: theme.spacing.unit * 2,
    },
  },
  awardImage: {
    backgroundImage: 'url(https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/award-emblem.webp)',
    backgroundSize: 'cover',
    width: 200,
    height: 200,
    margin: 50,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: theme.spacing.unit * 2,
      width: 120,
      height: 120,
    },
  },
  appInfo: {
    background: 'linear-gradient(360deg, rgba(196, 196, 196, 0.16) -23.24%, rgba(196, 196, 196, 0) 44.4%)',
    paddingTop: theme.spacing.unit * 8,
    paddingLeft: theme.spacing.unit * 8,
    paddingRight: theme.spacing.unit * 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: 0,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
  },
  appInfoSection: {
    maxWidth: 450,
    minWidth: 450,
    minHeight: 450,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      // width: 'auto',
      maxWidth: 'none',
      minWidth: 'auto',
      minHeight: 300,
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
    }
  },
  appInfoTitle: {
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '40px',
    color: '#FF6069',
    paddingBottom: theme.spacing.unit * 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '26px',
      marginTop: theme.spacing.unit * 6,
      paddingBottom: theme.spacing.unit * 2,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginTop: theme.spacing.unit * 4,
    },
  },
  appInfoText: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '30px',
    color: '#232323',
    paddingBottom: theme.spacing.unit * 3,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  fakePhone: {
    height: '100%',
    width: '100%',
    backgroundImage: 'url(https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/fake-phone.webp)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
  },
  sectionItems: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  sectionItem: {
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 4,
    },
  },
  sectionItemImage: {
  },
  map: {
    position: 'relative',
    height: 600,
    backgroundColor: '#F2F2F2',
    border: '3px solid #000000',
    borderRadius: 5,
  },
  mapNeighborhoodSelector: {
    position: 'absolute',
    top: 30,
    right: 30,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 10,
    },
  },
  citySelectorCta: {
    fontFamily: 'Hiragino Sans',
    minWidth: 175,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    borderRadius: 36,
    [theme.breakpoints.down('sm')]: {
      minWidth: 125,
      paddingTop: theme.spacing.unit / 2,
      paddingBottom: theme.spacing.unit / 2,
    },
  },
  locationPinIcon: {
    height: 22,
    width: 16,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      height: 18,
      width: 12,
    },
  },
  cityName: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  downArrowIcon: {
    height: 7,
    width: 15,
    marginLeft: 'auto',
  },
  cityDropdown: {
    backgroundColor: '#ffffff',
    maxHeight: 164,
    width: 160,
    borderRadius: 3,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    overflow: 'auto',
    marginTop: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      width: 125,
    },
  },
  cityListItem: {
    '&:hover': {
      backgroundColor: 'rgba(255, 96, 105, 0.15)',
    },
  },
  cityListItemText: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '20px',
    color: '#666666',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  mealImages: {
    marginBottom: theme.spacing.unit * -4,
  },
  mealImageContainer: {
    position: 'relative',
    boxSizing: 'border-box',
    padding: theme.spacing.unit * 2,
    outline: 'none',
  },
  mealImage: {
    borderRadius: 3,
  },
  mealShadow: {
    background: 'linear-gradient(89.88deg, rgba(0, 0, 0, 0.3) 3.92%, rgba(255, 255, 255, 0) 73.7%)',
    position: 'absolute',
    top: theme.spacing.unit * 2,
    left: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    bottom: theme.spacing.unit * 2,
    borderRadius: 3,
  },
  mealNeighborhood: {
    position: 'absolute',
    top: 30,
    left: 40,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      left: 30,
    },
  },
  mealLocationPin: {
    height: 17,
    width: 13,
  },
  mealNeighborhoodText: {
    color: '#ffffff',
    fontSize: 18,
    marginLeft: theme.spacing.unit,
    marginTop: -4,
  },
  mealInfo: {
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 40,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      left: 30,
      right: 30,
    },
  },
  mealTitle: {
    fontSize: 18,
    lineHeight: '32px',
    letterSpacing: '0.05em',
    fontWeight: 'bold',
    color: '#FFFFFF',
    borderBottom: '1px solid #FFFFFF',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '24px',
    },
  },
  mealSubtitle: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.05em',
    color: '#FFFFFF',
    marginTop: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  plans: {
    display: 'flex',
    marginLeft: -10,
    marginRight: -10,
    justifyContent: 'center',
    paddingBottom: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  plan: {
    flex: 1,
    minWidth: 310,
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit * 2,
    }
  },
  plansDivider: {
    borderBottom: '1px solid #FFFFFF',
  },
  plansTitle: {
    fontSize: 32,
    fontWeight: '500',
    lineHeight: '48px',
    letterSpacing: '0.1em',
    color: Colors.white,
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 6,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      fontWeight: '500',
      lineHeight: '24px',
    }
  },
  testimonials: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    maxWidth: 750,
    margin: '0 auto',
    marginBottom: theme.spacing.unit * -6,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      maxWidth: 280,
    }
  },
  testimonial: {
    flex: '0 50%',
    maxWidth: 348,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 6,
    [theme.breakpoints.down('sm')]: {
      flex: '0 100%',
      maxWidth: 280,
    }
  },
  testimonialImage: {
    flex: 1,
    height: 228,
    width: 228,
    [theme.breakpoints.down('sm')]: {
      height: 160,
      width: 'auto',
    }
  },
  testimonialText: {
    textAlign: 'center',
  },
  testimonialTextPrimary: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: '500',
    color: '#B0B4BA',
    marginTop: theme.spacing.unit * 3,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  testimonialTextSecondary: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: '500',
    color: '#232323',
    marginTop: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  testimonialTextCaption: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#232323',
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '24px',
    },
  },
  mediaLogos: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -10,
    marginRight: -10,
    justifyContent: 'center',
    // marginBottom: theme.spacing.unit * 2,
  },
  mediaLogoContainer: {
    width: 120,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '-webkit-filter': 'grayscale(1)',
  },
  faqItem: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  faqTitle: {
    fontSize: 14,
    fontWeight: '600',
  },
  faqBody: {
    marginTop: theme.spacing.unit,
    textAlign: 'left',
  },
  scrollSecondaryText: {
    marginTop: 0,
    fontSize: '12px',
    fontWeight: '400',
  },
})

class LandingPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mapLocation: MAP_LOCATIONS[0],
      mapLocationSelectorShowing: false,
      viewport: {
        zoom: MAP_LOCATIONS[0].zoom,
        latitude: MAP_LOCATIONS[0].latitude,
        longitude: MAP_LOCATIONS[0].longitude,
        transitionDuration: 2000,
        transitionEasing: mapEasing,
      },
      popup: null,
      expandedPanelIndex: null,
    }
  }

  onViewportChange = viewport => {
    this.setState({
      viewport,
    })
  }

  onClickMapLocationCta = () => {
    this.setState({
      mapLocationSelectorShowing: true,
    })
  }

  onSelectMapLocation = mapLocation => {
    this.setState({
      mapLocation,
      viewport: {
        zoom: mapLocation.zoom,
        latitude: mapLocation.latitude,
        longitude: mapLocation.longitude,
      },
      mapLocationSelectorShowing: false,
    })
  }

  onClickMap = event => {
    const feature = event.features.find(feature => feature.layer?.id === 'shops-layer')

    if (feature) {
      const shop = this.props.shops.data.find(shop => shop.id === feature.id)

      const viewport = getViewportForCoordinates({
        coordinates: [{
          latitude: shop.location.lat,
          longitude: shop.location.lon,
        }],
      })

      let bannerImage = DEFAULT_SHOP_IMAGE

      if (shop.media && shop.media.bannerImage) {
        bannerImage = getUrlForPhoto({ uuid: shop.media.bannerImage }, {
          size: 'medium',
        })
      }

      this.setState({
        viewport: {
          ...this.state.viewport,
          ...viewport,
          transitionDuration: 700,
          transitionEasing: mapEasing,
        },
        popup: {
          shop,
          image: bannerImage,
        }
      })
    }
  }

  onChangeExpandedPanel = index => {
    if (index === this.state.expandedPanelIndex) {
      this.setState({
        expandedPanelIndex: null,
      })
    } else {
      this.setState({
        expandedPanelIndex: index,
      })
    }
  }

  onClickSignup = () => {
    window.location = '/signup'
  }

  componentDidMount() {
    Channel.boot()
  }


  componentWillUnmount() {
    if (process.browser) {
      window.document.removeEventListener('scroll', this.listenScrollEvent)
      Channel.shutdown()
    }
  }

  render() {
    let copy

    if (this.props.query.descriptor && DYNAMIC_COPY[this.props.query.descriptor]) {
      copy = merge(DYNAMIC_COPY.default, DYNAMIC_COPY[this.props.query.descriptor], { arrayMerge: overwriteMerge })
    } else {
      copy = DYNAMIC_COPY.default
    }

    return (
      <div className={this.props.classes.container}>
        <Head>
          <link href="https://fonts.googleapis.com/css2?family=Comfortaa&family=Indie+Flower&display=swap" rel="stylesheet" />
          <script dangerouslySetInnerHTML={{
            __html: `
              (function(c,l,a,r,i,t,y){
                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "81ysr2vtlx");
            `
          }} />
        </Head>
        <PublicHeader transitionHeight={HEADER_TRANSITION_HEIGHT} />
        <div className={this.props.classes.hero}>
          <Fade
            canSwipe={false}
            pauseOnHover={false}
            arrows={false}
            easing="ease"
            duration={HERO_IMAGE_DURATION}
            transitionDuration={HERO_IMAGE_TRANSITION_DURATION}>
            {HERO_IMAGES.map(image => (
              <div
                key={image}
                className={this.props.classes.heroImage}
                style={{'backgroundImage': `url(${image})`}} />
            ))}
          </Fade>
          <div className={this.props.classes.heroContentWrapper}>
            <div className={this.props.classes.heroContent}>
              <div className={this.props.classes.heroTitle}>
                {renderDynamicCopyString(copy.hero.title)}
              </div>
              <div className={this.props.classes.heroText}>
                {renderDynamicCopyString(copy.hero.text)}
              </div>
              <div className={this.props.classes.learnMoreCtas}>
              {copy.hero.appDownload ? (
                <div className={this.props.classes.heroCtaAppLinks}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/jp/app/potluck/id1469071174">
                    <div className={this.props.classes.ctaAppstore} />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.potluckandroid5">
                    <div className={this.props.classes.ctaPlaystore} />
                  </a>
                </div>
              ) : (
                <div className={this.props.classes.awardImage} />
              )}
            </div>
            </div>
          </div>
          <div className={this.props.classes.heroGradient} />
        </div>
        <div className={this.props.classes.appInfo}>
          <div className={this.props.classes.appInfoSection}>
            <div className={this.props.classes.appInfoTitle}>
              {renderDynamicCopyString(copy.appInfo.title)}
            </div>
            <div className={this.props.classes.appInfoText}>
              {renderDynamicCopyString(copy.appInfo.text1)}
            </div>
            <div className={this.props.classes.appInfoText}>
              {renderDynamicCopyString(copy.appInfo.text2)}
            </div>
            <div className={this.props.classes.appInfoText}>
              {renderDynamicCopyString(copy.appInfo.text3)}
            </div>
          </div>
          <div className={classNames(this.props.classes.appInfoSection, this.props.classes.fakePhone)} />
        </div>
        <Section
          title="FEATURES"
          subtitle="ポットラックの３つの特徴">
          <div className={this.props.classes.sectionItems}>
            <SectionItem
              text={(
                <>
                  {renderDynamicCopyString(copy.features.text1)}
                </>
              )}
              image="https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/features-1.webp"
              className={this.props.classes.sectionItem}
              imageClassName={this.props.classes.sectionItemImage} />
            <SectionItem
              text={(
                <>
                  {renderDynamicCopyString(copy.features.text2)}
                </>
              )}
              image="https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/features-2.webp"
              className={this.props.classes.sectionItem}
              imageClassName={this.props.classes.sectionItemImage} />
            <SectionItem
              text={(
                <>
                  {renderDynamicCopyString(copy.features.text3)}
                </>
              )}
              image="https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/features-3.webp"
              className={this.props.classes.sectionItem}
              imageClassName={this.props.classes.sectionItemImage} />
          </div>
        </Section>
        <Section
          title="SHOPS"
          subtitle="ご利用いただける加盟店"
          color="pink">
          <div className={this.props.classes.map}>
            {this.state.viewport && (
              <ExploreMap
                viewport={this.state.viewport}
                shops={this.props.shops}
                popup={this.state.popup}
                onClickMap={this.onClickMap}
                onViewportChange={this.onViewportChange} />
            )}
            <div className={this.props.classes.mapNeighborhoodSelector}>
              <MuiButton
                variant="contained"
                style={{
                  backgroundColor: Colors.primaryPink,
                }}
                className={this.props.classes.citySelectorCta}
                onClick={this.onClickMapLocationCta}>
                <LocationPinIcon className={this.props.classes.locationPinIcon} />
                <div className={this.props.classes.cityName}>
                  {this.state.mapLocation.label}
                </div>
                <DownArrowIcon className={this.props.classes.downArrowIcon} />
              </MuiButton>
              <Collapse in={this.state.mapLocationSelectorShowing}>
                <div className={this.props.classes.cityDropdown}>
                  <List>
                    {MAP_LOCATIONS.map(mapLocation => (
                      <ListItem
                        key={mapLocation.value}
                        button
                        classes={{
                          root: this.props.classes.cityListItem,
                        }}
                        onClick={() => this.onSelectMapLocation(mapLocation)}>
                        <ListItemText
                          disableTypography
                          classes={{
                            root: this.props.classes.cityListItemText,
                          }}
                          primary={mapLocation.label} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Collapse>
            </div>
          </div>
        </Section>
        <Section
          noHorizontalPadding
          title="RECOMMENDED MENU"
          subtitle={renderDynamicCopyString(copy.recommend.subtitle)}>
          <Slider
            centerMode
            infinite
            autoplay
            swipe={false}
            arrows={false}
            pauseOnHover={false}
            dots={false}
            speed={700}
            easing="easeIn"
            autoplaySpeed={3000}
            responsive={[{
              breakpoint: BREAKPOINTS['sm'],
              settings: {
                slidesToShow: 1,
              }
            }, {
              breakpoint: BREAKPOINTS['xl'],
              settings: {
                slidesToShow: 3,
              }
            }, {
              breakpoint: BREAKPOINTS['xl'] * 2,
              settings: {
                slidesToShow: 5,
              }
            }, {
              breakpoint: 9999,
              settings: {
                slidesToShow: 7,
              }
            }]}
            className={this.props.classes.mealImages}
            customPaging={() => false}>
            {MEALS.map(meal => (
              <div
                key={meal.id}
                className={this.props.classes.mealImageContainer}>
                <Image
                  src={meal.image}
                  className={this.props.classes.mealImage}
                  renderLoader={LazyLoader} />
                <div className={this.props.classes.mealShadow} />
                <div className={this.props.classes.mealNeighborhood}>
                  <LocationPinIcon className={this.props.classes.mealLocationPin} />
                  <div className={this.props.classes.mealNeighborhoodText}>
                    {meal.neighborhood}
                  </div>
                </div>
                <div className={this.props.classes.mealInfo}>
                  <div className={this.props.classes.mealTitle}>
                    {meal.name}
                  </div>
                  <div className={this.props.classes.mealSubtitle}>
                    {meal.shop}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Section>
        <Section
          title="HOW TO USE"
          subtitle="使い方はかんたん３ステップ">
          <div className={this.props.classes.sectionItems}>
            <SectionItem
              title={renderDynamicCopyString(copy.howToUse.section1.title)}
              text={(
                <>
                  {renderDynamicCopyString(copy.howToUse.section1.text)}
                </>
              )}
              image="https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/tutorial-1.webp"
              className={this.props.classes.sectionItem}
              imageClassName={this.props.classes.sectionItemImage} />
            <SectionItem
              title={renderDynamicCopyString(copy.howToUse.section2.title)}
              text={(
                <>
                  {renderDynamicCopyString(copy.howToUse.section2.text)}
                </>
              )}
              image="https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/tutorial-2.webp"
              className={this.props.classes.sectionItem}
              imageClassName={this.props.classes.sectionItemImage} />
            <SectionItem
              title={renderDynamicCopyString(copy.howToUse.section3.title)}
              text={(
                <>
                  {renderDynamicCopyString(copy.howToUse.section3.text)}
                </>
              )}
              image="https://potluck-web.s3.ap-northeast-1.amazonaws.com/lp/tutorial-3.webp"
              className={this.props.classes.sectionItem}
              imageClassName={this.props.classes.sectionItemImage} />
          </div>
        </Section>
        <Section
          title="PLAN"
          subtitle="３つの料金プラン"
          color="pink">
          <div className={this.props.classes.plans}>
            {copy.plans.map((plan, index) => (
              <PlanCard
                key={index}
                className={this.props.classes.plan}
                plan={plan} />
            ))}
          </div>
          <div className={this.props.classes.plansDivider} />
          <div className={this.props.classes.plans}>
            {BUNDLES.length > 0 && (
              <div>
                <div className={this.props.classes.plansTitle}>
                  ＼ 買い切りプラン新登場／
                </div>
                {BUNDLES.map(plan => (
                  <PlanCard
                    key={plan.title}
                    fat
                    className={this.props.classes.bundle}
                    plan={plan} />
                ))}
              </div>
            )}
          </div>
        </Section>
        <Section
          title="USER'S VOICE"
          subtitle="ご利用者様の声">
          <div className={this.props.classes.testimonials}>
            {TESTIMONIALS.map((testimonial, index) => (
              <div
                key={index}
                className={this.props.classes.testimonial}>
                <div className={this.props.classes.testimonialImage}>
                  {testimonial.image}
                </div>
                <div className={this.props.classes.testimonialTextPrimary}>
                  {testimonial.primaryText}
                </div>
                <div className={this.props.classes.testimonialTextSecondary}>
                  {testimonial.secondaryText}
                </div>
                <div className={this.props.classes.testimonialTextCaption}>
                  {testimonial.caption}
                </div>
              </div>
            ))}
          </div>
        </Section>
        <Section
          title="MEDIAS"
          subtitle="メディア掲載">
          <div className={this.props.classes.mediaLogos}>
            {MEDIA_LOGOS.map(({ image, link, height, width }) => (
              <div className={this.props.classes.mediaLogoContainer}>
                <Image
                  key={image}
                  src={image}
                  width={width}
                  height={height}
                  renderLoader={LazyLoader} />
              </div>
            ))}
          </div>
        </Section>
        <Section title="FAQ">
          {FAQ_QUESTIONS.map((item, index) => (
            <Accordion
              key={index}
              expanded={this.state.expandedPanelIndex === index}
              className={this.props.classes.faqItem}
              onChange={() => this.onChangeExpandedPanel(index)}>
              <AccordionSummary
                expandIcon={(<ExpandMoreIcon />)}
                className={this.props.classes.faqTitle}>
                {item.question}
              </AccordionSummary>
              <AccordionDetails className={this.props.classes.faqBody}>
                {item.answer}
              </AccordionDetails>
            </Accordion>
          ))}
        </Section>
        {copy.bottomButton && (
          <Button
            style={{
              zIndex: 10,
              position: 'fixed',
              bottom: 'calc(16px + env(safe-area-inset-bottom))',
              left: 'calc(50% - 150px)',
              width: 300,
              maxWidth: '90vw',
              boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
            }}
            text={'無料でアカウント作成　＞'}
            secondaryText={'今なら1食313円のお試しキャンペーン中！'}
            secondaryTextClassName={this.props.classes.scrollSecondaryText}
            onClick={this.onClickSignup} />
        )}
        <Footer />
      </div>
    )
  }
}

LandingPage.getInitialProps = async context => {
  await setCookieAndLoadProfile(context)

  redirectIfLoggedIn(context)

  const state = context.store.getState()

  if (!state?.api?.shops?.default?.loaded) {
    await context.store.dispatch(getShops())
  }

  return {
    pathname: context.pathname,
    query: context.query,
  }
}

const mapStateToProps = state => ({
  shops: state.api.shops.default,
})

export default connect(mapStateToProps)(withStyles(styles)(LandingPage))
